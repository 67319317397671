<template>

  <b-modal
    hide-footer
    :visible="isModalActive"
    :title="$t('Detail')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-tabs
        content-class="pt-1"
        fill
      >
        <b-tab
          :title="$t('General')"
        >
          <b-row>
            <b-col cols="3">
              {{ $t('Score')+ ": " }}
            </b-col>
            <b-col cols="9">
              <p
                class="align-text-bottom line-height-1"
              >{{ data.score }}</p>
            </b-col>
            <b-col cols="3">
              {{ $t('Message')+ ": " }}
            </b-col>
            <b-col cols="9">
              <p
                v-if="data.message != '' "
                class="align-text-bottom line-height-1"
              >{{ data.message }}</p>
              <p
                v-else
                class="align-text-bottom line-height-1"
              >
                -
              </p>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col
              cols="12"
              class="pl-1"
            >
              <b-row>
                <b-col
                  v-for="(item,index) in data.questions"
                  :key="index"
                  cols="12"
                >
                  <b><span>{{ item.question }}</span></b>
                  <div
                    v-if="item.type='many_options'"
                  >
                    <b-row
                      v-for="(item2, index2) in item.listOption"
                      :key="index2"
                      class="pl-2"
                    >
                      <p v-if="item2.answer">
                        - {{ item2.option }}
                      </p>
                    </b-row>
                  </div>
                  <div v-else>
                    <p v-if="item.answer != ''">
                      {{ item.answer }}
                    </p>
                    <p v-else>
                      -
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
          <div />
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,

  BOverlay,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// import Detail from './components/Detail.vue'
// import Timeline from './components/Timeline.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      dataLoading: false,
      required,
    }
  },
  computed: {

  },
  watch: {
  },
  methods: {
    initValues() {
      this.dataId = null
      this.dataResponse = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
